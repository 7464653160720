import { get, post, put, remove } from "../_methods/download";

export const historyService = {
  getHistory
};

async function getHistory(page, pageSize, filter) {
  let type = filter && filter.type ? `&type=${filter.type}` : "";
  let start = filter && filter.start ? `&start=${filter.start}` : "";
  let end = filter && filter.end ? `&end=${filter.end}` : "";
  let id = filter && filter.id ? `&id=${filter.id}` : "";
  return await get(`/api/admin/audits?page=${page}&pageSize=${pageSize}${type}${start}${end}${id}`, true);
}

export default historyService;

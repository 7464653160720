import "../App.scss";

export async function printElement(id, name, head = null) {
  var element = window.open('', 'PRINT');

  element.document.write('<html>');
  if (head) {
    //head.childNodes.filter(x => x.id !== "MathJax");

    let mathJax = null;
    head.childNodes.forEach(x => {
      if (x.id === "MathJax")
        mathJax = x;
      }
    );
    if (mathJax)
      head.removeChild(mathJax);

    element.document.write(head.outerHTML);
  }
  else {
    element.document.write('<head><title>' + name + '</title>');
    element.document.write('</head>');
  }
  element.document.write('<body >');
  element.document.write(document.getElementById(id).innerHTML);
  await element.document.write('</body></html>');

  element.document.close(); // necessary for IE >= 10
  element.focus(); // necessary for IE >= 10*/

  setTimeout(() => {
    //element.print();
    //element.close();
  }, 2000);

  return true;
  }



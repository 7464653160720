import {get, post} from "../_methods/download"

export const loggingService = {
    sendLog
};

export async function sendLog(logInfo) {
    return await post(`api/utility/log`, false, logInfo);
}

export default loggingService;

import { auth, userService, GlobalContext } from './../_services';
import { processApiCall } from "../_methods/download";

export async function RefreshToken() {
  var user = auth.currentUserValue;
  let token;
  if (user)
    token = user.access_token;
  else {
    localStorage.removeItem("currentAuth");
    localStorage.removeItem("currentUserData");
    window.location.href = "/#/login";
  }
  return await fetch('/api/auth/refresh',
    {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
          , 'Authorization': `Bearer ${token}`
        }
    }).then(async (tokenResponse) => {
         if (tokenResponse.status === 401) {
           if (!window.location.hash.includes("login"))
             localStorage.setItem("returnUrl", window.location && `/${window.location.hash}`);
           window.location.href = "/#/login";
           return null;
         } else {
           tokenResponse = await tokenResponse.json();
           return tokenResponse;
         }
    }).then(async (tokenResponse) => {
      if ((tokenResponse && tokenResponse.data && tokenResponse.data.access_token != null)) {
        await auth.updateAccessToken(tokenResponse.data.access_token, tokenResponse.data.expires_in_timestamp);
        await userService.refreshUser();
        return true;
      }
		  else {
         if (!window.location.hash.includes("login"))
           localStorage.setItem("returnUrl", window.location && `/${window.location.hash}`);
         window.location.href = "/#/login";
         return false;
		  }
    });
}

export async function Fetch(resource, init, addAuthHeader = true, queryIfNotAuthed = false) {
    if (addAuthHeader) {
        let token = await auth.getAccessToken();
        if (token || queryIfNotAuthed) {
            if (init == null) {
                init = {}
            }
            if (init.headers == null) {
                init.headers = {}
            }
            if (token) {
                init.headers["Authorization"] = `Bearer ${token}`;
            }
        } else {
          if (window.location.hash.includes("token=")) {
            token = window.location.hash.match(`token=(.*)(?=&)`);
            if (token)
              token = token[1];
            else
              token = window.location.hash.match(`token=(.*)`)[1];
            let authToken = window.location.hash.match(`authToken=(.*)`);
            if (authToken)
              authToken = authToken[1];
            let authEmailToken = window.location.hash.match(`emailToken=(.*)`);
            if (authEmailToken)
              authEmailToken = authEmailToken[1];
            if (authToken || authEmailToken)
              localStorage.setItem("requestInvite", JSON.stringify({ token: token, authToken: authToken, authEmailToken: authEmailToken }));
            else {
              token = window.location.hash.match(`token=(.*)`)[1];
              localStorage.setItem("requestInvite", JSON.stringify({ token: token, authToken: authToken, authEmailToken: authEmailToken }));
            }
            if (authToken !== null) {
              window.location.href = "/#/password/change";
              return { data: null, success: true }
            }
            if (authEmailToken !== null) {
              await processApiCall(auth.getByEmailToken(authEmailToken),
                (data) => {
                  if (data.phoneConfirmed) {
                    window.location.href = "/#/login";
                    
                  } else {
                    window.location.href = "/#/preregister";
                  }
                  return { data: null, success: true }
                }, outError, outError);
              return { data: null, success: true }
            }
          }
        }
    }

    let res;
  try {
    res = await fetch(resource, init);
  } catch (err) {
    let x = err;
    let y = err ? err.message : err;
    debugger;
  }
    var user = auth.currentUserValue;

    if (res.status === 401 && user) {
      console.log("Getting new response " + res.status);
      let refreshed = await RefreshToken();
      let result = refreshed ? await Fetch(resource, init, addAuthHeader, queryIfNotAuthed) : null;
      return result;
    } else if (res.status === 401 && !window.location.hash.includes("change") && !window.location.hash.includes("preregister")) {
      if (!window.location.hash.includes("login"))
        localStorage.setItem("returnUrl", window.location && `/${window.location.hash}`);
	    window.location.href = "/#/login";
      return null;
  }

  return res;
}

function outError() {
  window.location.href = "/#/login";
}

export async function FetchAllowAnonymous(resource, init) {
  return await Fetch(resource, init, true, true);
}

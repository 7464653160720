//import config from 'config';
import { get, post, postFile, remove } from "../_methods/download";

export const externalService = {
  getLocationSettings,
  getRegions,
  getDistricts,
  getSchools,
  createPayment,
  createPaymentMultiple,
  getExCategories,
  getExContests,
  sendSmsPublic,
  checkSmsPublic,
  sendSmsPayment,
  checkSmsPayment,
  getContest,

  getProvincesGeo,
  getProvincesGeoByReportCategoryId,
  getRegionsGeo,
  getDistrictsGeo,
  getSchoolsGeo,

  applyPromo,
  getOverrideCosts,
  getPaymentStatus
};

async function getLocationSettings(regionId, reportCategoryId) {
  let regions = reportCategoryId ? await getProvincesGeoByReportCategoryId(reportCategoryId) : await getProvincesGeo();
  let districts = regionId ? await getRegionsGeo(regionId) : {data:[]};
  let cities = regionId ? await getDistrictsGeo(regionId) : {data:[]};
  let schools = regionId ? await getSchoolsGeo(regionId, reportCategoryId) : { data: [] };
  let response = {
    data: {
      regions: regions.data,
      districts: districts.data,
      cities: cities.data,
      schools: schools.data
    }
  };
  return response;
}

async function getRegions() {
  let response = await get(`/api/public/location/regions`, true);
  return response;
}

async function getDistricts(regionId) {
  let response = await get(`/api/public/location/districts?province=${regionId}`, true);
  return response;
}

async function getSchools(regionId, districtId, cityId) {
  regionId = regionId ? regionId : "";
  districtId = districtId ? districtId : "";
  let response = await get(`/api/public/location/schoolsGroupByCity?region=${regionId}&district=${districtId}&deleted=false`, true);
  return response;
}

async function getProvincesGeo() {
  let response = await get(`/api/public/location/geoProvinces`, true);
  return response;
}

async function getProvincesGeoByReportCategoryId(reportCategoryId) {
  let response = await get(`/api/public/location/geoProvinces/${reportCategoryId}`, true);
  return response;
}

async function getRegionsGeo(regionId) {
  let response = await get(`/api/public/location/geoRegions?province=${regionId}`, true);
  return response;
}

async function getDistrictsGeo(regionId) {
  let response = await get(`/api/public/location/geoDistricts?province=${regionId}`, true);
  return response;
}

async function getSchoolsGeo(regionId, reportCategoryId) {
  regionId = regionId ? regionId : "";
  let response = await get(`/api/public/location/geoSchoolsGroupByCity/${reportCategoryId}?province=${regionId}&deleted=false`, true);
  return response;
}

async function createPayment(model) {
  return await post(`/api/public/onlinepayment/create`, true, model);
}

async function createPaymentMultiple(model) {
  return await post(`/api/public/onlinepayment/create/multiple`, true, model);
}

async function getExCategories() {
  return await get(`/api/public/contestCategories`, true);
}

async function getExContests(categoryId) {
  let category = categoryId ? `?category=${categoryId}` : "";
  return await get(`/api/public/contests${category}`, true);
}

async function getContest(contestId) {
  return await get(`/api/public/contests/${contestId}`, true);
}

async function sendSmsPublic(model) {
  return await post(`/api/auth/password/restore`, false, JSON.stringify(model));
}

async function checkSmsPublic(model) {
  return await post(`/api/auth/password/restore/confirm`, false, JSON.stringify(model));
}

async function sendSmsPayment(id, model) {
  return await post(`/api/public/payments/${id}/passwordRestore`, false, JSON.stringify(model));
}

async function checkSmsPayment(id, model) {
  return await post(`/api/public/payments/${id}/passwordRestoreConfirm`, false, JSON.stringify(model));
}

async function applyPromo(model) {
  return await post(`api/public/checkPromoCodeOnlinePayment`, true, JSON.stringify(model));
}

async function getOverrideCosts(categoryId, schoolId = null) {
  return await get(`api/public/prices/${categoryId}${schoolId ? `?schoolId=${schoolId}` : ''}`, true);
}

async function getPaymentStatus(paymentId) {
  return await get(`/api/public/paymentStatus/${paymentId}`, true);
}

export default externalService;

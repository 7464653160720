import { get, post, put, remove } from "../_methods/download";

export const promoService = {
  createPromo,
  getAll,
  getPromo,
  updatePromo,
  removePromo
};

async function getAll(page, pageSize, type) {
  let deleted =  `&deleted=${type !== 1}`;
  return await get(`/api/admin/promoCodes?page=${page}&pageSize=${pageSize}${deleted}`, true);
}

async function getPromo(promoId) {
  return await get(`/api/admin/promoCodes/${promoId}`, true);
}
async function createPromo(model) {
  return await post(`/api/admin/promoCodes`, true, model);
}
async function updatePromo(promoId, model) {
  return await put(`/api/admin/promoCodes/${promoId}`, true, model);
}
async function removePromo(promoId) {
  return await remove(`/api/admin/promoCodes/${promoId}`, true);
}

export default promoService;

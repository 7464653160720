import { get, post, remove, put } from "../_methods/download";
import points from "../assets/img/widgets/points.svg";
import solvedTasks from "../assets/img/widgets/solved-tasks.svg";

export const widgetService = {
  getWidgets
};

let widgets = [
  {
    id: 1,
    name: "Количество баллов",
    text: "баллов",
    image: points,
    value: 100,
    externalLink: "https://ya.ru",
    type: 1 //points
  },
  {
    id: 2,
    name: "Количество решенных задач",
    text: "решенных задач",
    image: solvedTasks,
    value: 40,
    externalLink: "https://ya.ru",
    type: 2 //solvedTasks
  },
  //{
  //  id: 3,
  //  name: "Количество баллов",
  //  text: "баллов",
  //  image: points,
  //  value: 100,
  //  type: 1 //solvedTasks
  //},
];

async function getWidgets(requestId) {
  return { data: widgets };
  return await get(`/api/widgets/${requestId}`, true);
}

export default widgetService;

import { get, post, put, remove } from "../_methods/download";

export const paymentService = {
  getAccessKassa,
  getAllAdminPayments,
  getAccessSeriesKassa,
  getAccessCoordinatorPaymentKassa,
  getAccessPaymentKassa,
  createAdminPayment,
  updateAdminPayment,
  getAdminPayment,
  getAllCoordinatorPayments,
  //getAllTeacherPayments,
  //getAllStudentPayments,
  createCoordinatorPayment,
  updateCoordinatorPayment,
  getCoordinatorPayment,
  getTeacherOnlinePayments,
  getStudentOnlinePayments,

  getOnlinePayments,
  getOnlinePayment,
  createOnlinePayment,
  updateOnlinePayment,
  deleteOnlinePayment,

  getCoordinatorPayments,
  getPaymentStatus,
  getAdminPaymentsExcel,
  getCoordinatorPaymentsExcel
};

async function getAccessKassa(requestId, isRedirect = false, returnUrl = null) {
  let isRedirectFilter = isRedirect ? "&redirect=true" : "";
  returnUrl = returnUrl ? `&returnUrl=${returnUrl}` : "";
  return await get(`/api/requests/group/${requestId}/pay?${isRedirectFilter}${returnUrl}`, true);
}

async function getAccessPaymentKassa(paymentId, isRedirect = false, returnUrl = null) {
  let isRedirectFilter = isRedirect ? "&redirect=true" : "";
  returnUrl = returnUrl ? `&returnUrl=${returnUrl}` : "";
  return await post(`/api/public/payments/${paymentId}/pay?${isRedirectFilter}${returnUrl}`, true);
}

async function getAccessCoordinatorPaymentKassa(requestId) {
  return await post(`/api/coordinatorPayment/${requestId}/pay`, true);
}

async function getAccessSeriesKassa(requestId, isRedirect = false, returnUrl = null) {
  let isRedirectFilter = isRedirect ? "&redirect=true" : "";
  returnUrl = returnUrl ? `&returnUrl=${returnUrl}` : "";
  return await post(`/api/contestSeriesSubscriptions/${requestId}/pay?${isRedirectFilter}${returnUrl}`, true);
}

async function getAllAdminPayments(page, pageSize, filter) {
  const codeSchool = filter && filter.codeSchool ? `&codeSchool=${filter.codeSchool}` : "";
  const sumLt = filter && filter.sumLt ? `&sumLte=${filter.sumLt}` : "";
  const category = filter && filter.category ? `&category=${filter.category}` : "";
  const createdAtFrom = filter && filter.createdAtFrom ? `&createdAtGte=${filter.createdAtFrom}` : "";
  const createdAtTo = filter && filter.createdAtTo ? `&createdAtLte=${filter.createdAtTo}` : "";
  const status = '';//filter && filter.status ? `&status=${filter.status}` : "";
  const sumGt = filter && filter.sumGt ? `&sumGte=${filter.sumGt}` : "";
  return await get(`/api/admin/payments?page=${page}&pageSize=${pageSize}${codeSchool}${category}${sumGt}${createdAtFrom}${createdAtTo}${status}${sumLt}`, true);
}

async function createAdminPayment(model) {
  return await post(`/api/payments`, true, model);
}

async function updateAdminPayment(id, model) {
  return await put(`/api/payments/${id}`, true, model);
}

async function getAdminPayment(id) {
  return await get(`/api/payments/${id}`, true);
}

async function getAllCoordinatorPayments(page, pageSize, filter) {
  const purchaser = filter && filter.purchaser ? `&purchaser=${filter.purchaser}` : "";
  const contest = filter && filter.contest ? `&contest=${filter.contest}` : "";
  const createdAtFrom = filter && filter.createdAtFrom ? `&createdAtGte=${filter.createdAtFrom}` : "";
  const createdAtTo = filter && filter.createdAtTo ? `&createdAtLte=${filter.createdAtTo}` : "";
  const status = filter && filter.status ? `&status=${filter.status}` : "";
  const role = filter && filter.role ? `&role=${filter.role}` : "";
  return await get(`/api/admin/payments?page=${page}&pageSize=${pageSize}${purchaser}${contest}${createdAtFrom}${createdAtTo}${status}${role}`, true);
}

async function createCoordinatorPayment(model) {
  return await post(`/api/payments`, true, model);
}

async function getCoordinatorPayments(schoolId, contestId, page, pageSize, filter) {
  let contest = schoolId && contestId ? `&contest=${contestId}` : "";
  const schoolIds = filter && filter.schoolIds ? `&schoolIds=${filter.schoolIds}` : "";
  const district = filter && filter.district ? `&district=${filter.district}` : "";
  let school = "";
  if (district === "") {
    school = schoolIds == "" && schoolId ? `&school=${schoolId}` : "";
  }
  return await get(`/api/payments?page=${page}&pageSize=${pageSize}${school}${contest}${schoolIds}${district}`, true);
}

async function updateCoordinatorPayment(id, model) {
  return await put(`/api/payments/${id}`, true, model);
}

async function getCoordinatorPayment(id) {
  return await get(`/api/payments/${id}`, true);
}

async function getOnlinePayments(page, pageSize) {
  return await get(`/api/onlinePayments?page=${page}&pageSize=${pageSize}`, true);
}

async function getTeacherOnlinePayments(page, pageSize) {
  return await get(`/api/onlinePayments/teacher?page=${page}&pageSize=${pageSize}`, true);
}

async function getStudentOnlinePayments(page, pageSize) {
  return await get(`/api/onlinePayments/student?page=${page}&pageSize=${pageSize}`, true);
}

async function getOnlinePayment(paymentId) {
  return await get(`/api/onlinePayments/${paymentId}`, true);
}

async function createOnlinePayment(model) {
  return await post(`/api/onlinePayments/create`, true, model);
}

async function updateOnlinePayment(paymentId, model) {
  return await put(`/api/onlinePayments/update/${paymentId}`, true, model);
}

async function deleteOnlinePayment(paymentId) {
  return await remove(`/api/admin/payments/${paymentId}`, true);
}

async function getPaymentStatus(paymentId) {
  return await get(`/api/public/paymentStatus/${paymentId}`, true);
}

async function getAdminPaymentsExcel(filter) {
  let authData = localStorage.getItem("currentAuth");
  if (authData) {
    authData = JSON.parse(authData);
    const purchaser = filter && filter.purchaser ? `&purchaser=${filter.purchaser}` : "";
    const contest = filter && filter.contest ? `&contest=${filter.contest}` : "";
    const createdAtFrom = filter && filter.createdAtFrom ? `&createdAtGte=${filter.createdAtFrom}` : "";
    const createdAtTo = filter && filter.createdAtTo ? `&createdAtLte=${filter.createdAtTo}` : "";
    const status = filter && filter.status ? `&status=${filter.status}` : "";
    const role = filter && filter.role ? `&role=${filter.role}` : "";
    const sumLt = filter && filter.sumLt ? `&sumLte=${filter.sumLt}` : "";
    const sumGt = filter && filter.sumGt ? `&sumGte=${filter.sumGt}` : "";
    let link = window.location.protocol + "//" + window.location.host + `/api/admin/paymentsExcel?token=${authData.access_token}${purchaser}${contest}${createdAtFrom}${createdAtTo}${status}${role}${sumLt}${sumGt}`;
    return link;
  }
  return null;
}

async function getCoordinatorPaymentsExcel(coordinatorId, filter) {
  let authData = localStorage.getItem("currentAuth");
  if (authData) {
    authData = JSON.parse(authData);
    const codeSchool = filter && filter.codeSchool ? `&codeSchool=${filter.codeSchool}` : "";
    const sumLt = filter && filter.sumLt ? `&sumLte=${filter.sumLt}` : "";
    const category = filter && filter.category ? `&category=${filter.category}` : "";
    const createdAtFrom = filter && filter.createdAtFrom ? `&createdAtFrom=${filter.createdAtFrom}` : "";
    const createdAtTo = filter && filter.createdAtTo ? `&createdAtTo=${filter.createdAtTo}` : "";
    const sumGt = filter && filter.sumGt ? `&sumGte=${filter.sumGt}` : "";
    let link = window.location.protocol + "//" + window.location.host + `/api/coordinators/${coordinatorId}/paymentsExcel?token=${authData.access_token}${codeSchool}${sumLt}${category}${createdAtFrom}${createdAtTo}${sumGt}`;
    return link;
  }
  return null;
}

export default paymentService;

import { get, post, remove, put, postFile } from "../_methods/download";
import moment from "moment";
import { BehaviorSubject } from 'rxjs';

var notifications = localStorage.getItem('notifications') ? localStorage.getItem('notifications') : null;
const currentNotificationsSubject = new BehaviorSubject(JSON.parse(notifications));

export const notificationsService = {
  getNotifications,
  getUserNotification,
  getNotification,
  createNotification,
  updateNotification,
  removeNotification,

  getMobileNotifications,
  getMobileNotification,
  createMobileNotification,
  publishMobileNotification,

  getUserNotifications,
  getBulkMessages,
  setReadStatus,
  refreshNotifications,
  uploadFile,
  currentNotifications: currentNotificationsSubject.asObservable(),
  get currentValue() { return currentNotificationsSubject.value },
  get currentAsync() { return (async () => { return await getUserNotifications() })(); }
};

async function refreshNotifications(clear = false) {
  if (clear)
    currentNotificationsSubject.next(null);
  return clear ? null : await getUserNotifications(true);
}

async function getNotifications(page, pageSize) {
  return await get(`/api/notifications?page=${page}&pageSize=${pageSize}&deleted=false`, true);
}

async function getUserNotification() {
  return await get(`/api/notifications/user`, true);
}

async function getUserNotifications() {
  let notif = await get(`/api/account/notifications`, true);
  if (notif && !notif.error)
    currentNotificationsSubject.next(notif.data);
  return notif;
}

async function setReadStatus(notificationId) {
  return await post(`/api/notifications/${notificationId}/setRead`, true, null);
}

async function getNotification(id) {
  return await get(`/api/notifications/${id}`, true);
}

async function createNotification(model) {
  return await post(`/api/notifications`, true, model);
}

async function updateNotification(id, model) {
  return await put(`/api/notifications/${id}`, true, model);
}

async function removeNotification(id) {
  return await remove(`/api/notifications/${id}`, true);
}

async function getMobileNotifications(id, page, pageSize, filter) {
  const sentAtGt = filter && filter.sentAtGt ? `&sentAtGt=${moment(filter.sentAtGt).format("YYYY-MM-DD")}` : "";
  const sentAtLt = filter && filter.sentAtLt ? `&sentAtLt=${moment(filter.sentAtLt).format("YYYY-MM-DD")}` : "";
  const user = filter && filter.user ? `&user=${filter.user}` : "";
  return await get(`/api/mobileNotifications?bulkMessage=${id}&page=${page}&pageSize=${pageSize}${sentAtGt}${sentAtLt}${user}`, true);
}

async function getMobileNotification(id) {
  return await get(`/api/bulkMessages/${id}`, true);
}

async function getBulkMessages(page, pageSize) {
  return await get(`/api/bulkMessages?page=${page}&pageSize=${pageSize}`, true);
}

async function createMobileNotification(model) {
  return await post(`/api/bulkMessages`, true, model);
}

async function publishMobileNotification(id) {
  return await post(`/api/bulkMessages/${id}/publish`, true);
}

async function uploadFile(/*notificationId,*/ model) {
  return await postFile(`/api/notifications/uploadAttachment`, true, model);
}

export default notificationsService;

import { get, post, put, remove } from "../_methods/download";

export const locationService = {
  getRegions,
  getRegionsSelect,
  getRegion,
  createRegion,
  updateRegion,

  getProvinceGeo,
  updateProvinceGeo,
  createProvinceGeo,
  getProvincesGeo,
  getProvincesSelectGeo,

  getDistricts,
  getDistrictsSelect,
  getDistrict,
  createDistrict,
  updateDistrict,

  getRegionsGeo,
  getRegionsSelectGeo,
  getRegionGeo,
  createRegionGeo,
  updateRegionGeo,

  getDistrictGeo,
  createDistrictGeo,
  updateDistrictGeo,
  getDistrictsSelectGeo,
  getDistrictsGeo,

  getCities,
  getCitiesSelect,
  getCity,
  createCity,
  updateCity
  
  , createLastDistrict
  , createLastCity
};

/***old_regions***/
async function getRegions(page, sizePerPage, filter) {
  const regionCode = filter.regionCode ? filter.regionCode : "";
  let regionFilter = regionCode ? `&rc=${regionCode}` : "";
  return await get(`api/location/regions?page=${page}&pageSize=${sizePerPage}${regionFilter}`, true);
}

async function getRegionsSelect() {
  return await get(`api/profile/regions`, true);
}

async function getRegion(id) {
  return await get(`api/location/regions/${id}`, true);
}

async function createRegion(model) {
  return await post(`api/location/regions/create`, true, model);
}

async function updateRegion(id, model) {
  return await post(`api/location/regions/edit/${id}`, true, model);
}

/***end_old_regions***/

/***geo_provinces***/
async function getProvincesGeo(page, sizePerPage, filter) {
  const regionCode = filter.regionCode ? filter.regionCode : "";
  let regionFilter = regionCode ? `&rc=${regionCode}` : "";
  return await get(`api/location/geoProvinces?page=${page}&pageSize=${sizePerPage}${regionFilter}`, true);
}

async function getProvincesSelectGeo() {
  return await get(`api/profile/geoProvinces`, true);
}

async function getProvinceGeo(id) {
  return await get(`api/location/geoProvinces/${id}`, true);
}

async function createProvinceGeo(model) {
  return await post(`api/location/geoProvinces`, true, model);
}

async function updateProvinceGeo(id, model) {
  return await put(`api/location/geoProvinces/${id}`, true, model);
}

/***end_geo_provinces***/

/***old_districts***/
async function getDistricts(page, sizePerPage, filter) {
  const regionCode = filter.regionCode ? filter.regionCode : "";
  const districtCode = filter.districtCode ? filter.districtCode : "";
  return await get(`api/location/districts?page=${page}&pageSize=${sizePerPage}&rc=${regionCode}&dc=${districtCode}`, true);
}

async function getDistrictsSelect(regionId) {
  return await get(`api/profile/provinces?region=${regionId}`, true);
}

async function getDistrict(id) {
  return await get(`api/location/districts/${id}`, true);
}

async function createDistrict(model) {
  return await post(`api/location/districts/create`, true, model);
}

async function updateDistrict(id, model) {
  return await post(`api/location/districts/edit/${id}`, true, model);
}
/***end_old_districts***/

/***geo_regions***/
async function getRegionsGeo(page, sizePerPage, filter) {
  const regionCode = filter.regionCode ? filter.regionCode : "";
  const districtCode = filter.districtCode ? filter.districtCode : "";
  return await get(`api/location/geoRegions?page=${page}&pageSize=${sizePerPage}&rc=${regionCode}&dc=${districtCode}`, true);
}

async function getRegionsSelectGeo(regionId) {
  return await get(`api/profile/geoRegions?province=${regionId}`, true);
}

async function getRegionGeo(id) {
  return await get(`api/location/geoRegions/${id}`, true);
}

async function createRegionGeo(model) {
  return await post(`api/location/geoRegions`, true, model);
}

async function updateRegionGeo(id, model) {
  return await put(`api/location/geoRegions/${id}`, true, model);
}
/***end_geo_regions***/

/***old_cities***/
async function getCities(page, sizePerPage, filter) {
  const regionCode = filter.regionCode ? filter.regionCode : "";
  const districtCode = filter.districtCode ? filter.districtCode : "";
  const cityCode = filter.cityCode ? filter.cityCode : "";
  return await get(`api/location/cities?page=${page}&pageSize=${sizePerPage}&rc=${regionCode}&dc=${districtCode}&cc=${cityCode}`, true);
}

async function getCitiesSelect(districtId, regionId) {
  districtId = districtId ? districtId : "";
  regionId = regionId ? regionId : "";
  return await get(`api/profile/districts?province=${districtId}&region=${regionId}`, true);
}

async function getCity(id) {
  return await get(`api/location/cities/${id}`, true);
}

async function createCity(model) {
  return await post(`api/location/cities/create`, true, model);
}

async function updateCity(id, model) {
  return await post(`api/location/cities/edit/${id}`, true, model);
}
/***end_old_cities***/

/***geo_districts***/
async function getDistrictsGeo(page, sizePerPage, filter) {
  const regionCode = filter.regionCode ? filter.regionCode : "";
  const districtCode = filter.districtCode ? filter.districtCode : "";
  return await get(`api/location/geoDistricts?page=${page}&pageSize=${sizePerPage}&rc=${regionCode}&dc=${districtCode}`, true);
}

async function getDistrictGeo(id) {
  return await get(`api/location/geoDistricts/${id}`, true);
}

async function getDistrictsSelectGeo(districtId, regionId) {
  return await get(`api/location/geoDistricts?province=${districtId}&region=${regionId}`, true);
}

async function createDistrictGeo(model) {
  return await post(`api/location/geoDistricts`, true, model);
}

async function updateDistrictGeo(id, model) {
  return await put(`api/location/geoDistricts/${id}`, true, model);
}
/***end_geo_districts***/


async function createLastCity(model) {	
	return await post(`api/location/cities/create/last`, true, model);
}

async function createLastDistrict(model) {	
	return await post(`api/location/districts/create/last`, true, model);
}


export default locationService;

import { get, post, put, remove } from "../_methods/download";

export const tagService = {
  createTag,
  getAllTags,
  getTags,
  getTag,
  updateTag,
  removeTag,
  updateTags,
  getTeacherTags,
  getTeacherTagsByVariant,
  getTagsForArchiveStudent,
  getTagsForArchiveTeacher
};

async function getAllTags() {
  return await get(`/api/tasks`, true);
}

async function getTags(page, sizePerPage, filter, deleted = false) {
  let tagFilter = filter && filter.tag ? `&label=${filter.tag}` : "";
  return await get(`/api/tags?deleted=${deleted}&page=${page}&pageSize=${sizePerPage}${tagFilter}`, true);
}

async function getTag(tagId) {
  //return tags.data[0];
  return await get(`/api/tags/${tagId}`, true);
}
async function createTag(model) {
  return await post(`/api/tags`, true, model);
}
async function updateTag(tagId, model) {
  //return tags.data[0];
  return await put(`/api/tags/${tagId}`, true, model);
}
async function removeTag(tagId) {
  //return {data: "success"};
  return await remove(`/api/tags/${tagId}`, true);
}

async function updateTags(model) {
  return await get(`/api/tasks/updateTags`, true, model);
}

async function getTeacherTags(name) {
  let nameFilter = name ? `?name=${name}` : "";
  return await get(`/api/teacher/tags${nameFilter}`, true);
}

async function getTeacherTagsByVariant(variantId) {
  return await get(`/api/teacher/variants/${variantId}/tags`, true);
}

async function getTagsForArchiveStudent() {
  return await get(`api/requests/group/list/student/years`, true);
}

async function getTagsForArchiveTeacher() {
  return await get(`api/requests/group/list/teacher/years`, true);
}


export default tagService;

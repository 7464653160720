import { get, post, put, remove, postFile } from "../_methods/download";

export const searchArchiveService = {
  searchContests,
  getContestsForSearch,
  getHandleSearchResults,
  selectMe,
  autoSearchAvailable
};

async function searchContests(page, pageSize) {
  return await get(`/api/archive/search/auto?page=${page}&pageSize=${pageSize}`, true);
}

async function autoSearchAvailable() {
  return await get(`/api/archive/search/auto/available`, true);
}

async function getContestsForSearch() {
  return await get(`/api/archive/contests`, true);
}

async function getHandleSearchResults(contestId, page, pageSize) {
  return await get(`/api/archive/search/manual/${contestId}?page=${page}&pageSize=${pageSize}`, true);
}

async function selectMe(id) {
  return await post(`/api/archive/selectMe/${id}`, true);
}

export default searchArchiveService;

import { get, post, put, remove } from "../_methods/download";

export const dbService = {
  createDb,
  getDbs,
  getDb,
  updateDb,
  removeDb,
  //registerDb
};

async function getDbs(page, sizePerPage, select = null, active = null) {
  let activeFilter = active != null ? `&active=${active}` : "";
  let selectFilter = select != null ? `&select=${active}` : "";
  return await get(`/api/admin/databaseConnections?page=${page}&pageSize=${sizePerPage}${selectFilter}${activeFilter}`, true);
}
async function getDb(dbId) {
  return await get(`/api/admin/databaseConnections/${dbId}`, true);
}
async function createDb(model) {
  debugger;
  return await post(`/api/admin/databaseConnections`, true, model);
}
async function updateDb(dbId, model) {
  return await put(`/api/admin/databaseConnections/${dbId}`, true, model);
}
async function removeDb(dbId) {
  return await remove(`/api/admin/databaseConnections/${dbId}`, true);
}
//async function registerDb(id, model) {
//  return await post(`/api/dbs/${id}/register`, true, JSON.stringify(model));
//}

export default dbService;

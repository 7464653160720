import { get, post, put, remove } from "../_methods/download";

export const seriesService = {
  createSeriesSubscriptions,
  getMySeriesSubscriptions,
  getSeriesContests,
  getSeries,
  getSeriesResults,
  getContestant,
  applyPromo,
  getSeriesContestsResults
};

async function createSeriesSubscriptions(model) {
  return await post(`/api/contestSeriesSubscriptions`, true, model);
}

async function getMySeriesSubscriptions() {
  return await get(`/api/contestSeriesSubscriptions/my`, true);
}

async function getSeriesContests(seriesId) {
  return await get(`/api/contestSeriesSubscriptions/${seriesId}/requests`, true);
}

async function getSeriesContestsResults(seriesId) {
  return await get(`/api/contestSeriesSubscriptions/${seriesId}/requestsResults`, true);
}

async function getSeries(seriesId) {
  return await get(`/api/contestSeriesSubscriptions/${seriesId}`, true);
}

async function getContestant(seriesId, userId) {
  return await get(`/api/contestSeriesSubscriptions/${seriesId}/contestants/${userId}`, true);
}

async function getSeriesResults(seriesId) {
  return await get(`/api/contestSeriesSubscriptions/${seriesId}/topResults`, true);
}

async function applyPromo(seriesId, model) {
  return await post(`/api/contestSeriesSubscriptions/${seriesId}/applyPromo`, true, JSON.stringify(model));
}

export default seriesService;

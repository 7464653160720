import React from 'react'

export const GlobalContext = React.createContext(
  {
      loader: { isOpen: false },
      error: { message: null },
      applyRegisterRequest: { isOpen: false, requestId: null, isReject: false, isComment: false },
      createBlockVariant: { isOpen: false, block: null, isInit: false },
      createContestant: { isOpen: false, contestant: null, formats: [] },
      shareLinkGroupRequest: { isOpen: false },
      playerData: { contestant: null, tasks: [], task: null, selectedTask: null, endTime: null, requestId: null, contestantId: null, testNotLimited: false, taskVariants: [] },
      startTimeRequest: { isOpen: false, requestId: null, times: null },
      errorModal: { isOpen: false, text: null, returnUrl: null },
      resultErrors: { isOpen: false, errors: [] },
      awardSettings: { isOpen: false, errors: [] },
      selectContestants: { isOpen: false, isExistsRequest: false },
      setSchools: { isOpen: false, selectedSchools: [], id: null },
      setCoordinatorSchools: { isOpen: false, selectedSchools: [], id: null },
      coordinatorEditSchool: { isOpen: false, selectedSchoolId: null },
      coordinatorEditCity: { isOpen: false, selectedCityId: null, regionId:null, districtId:null },
      coordinatorEditDistrict: { isOpen: false, selectedDistrictId: null, regionId:null },
      contestants: [],
      newTasksForTeacherVariant: [],

      showLoader: () => { },
      hideLoader: () => { },
      setError: () => { },
      clearError: () => { },
      showApplyRegisterRequestModal: () => { },
      hideApplyRegisterRequestModal: () => { },
      showCreateBlockVariant: () => { },
      hideCreateBlockVariant: () => { },
      showContestantCreate: () => { },
      hideContestantCreate: () => { },
      showModalShareLinkGroupRequest: () => { },
      hideModalShareLinkGroupRequest: () => { },
      updatePlayerData: () => { },
      selectPlayerTask: () => {},
      showSetTimeRequest: () => {},
      hideSetTimeRequest: () => { },
      showModalError: () => {},
      hideModalError: () => { },
      showResultErrors: () => { },
      hideResultErrors: () => { },
      showAwardSettings: () => { },
      hideAwardSettings: () => { },
      showSelectContestants: () => { },
      hideSelectContestants: () => { },
      setContestants: () => { },
      clearContestants: () => { },
      showSetSchools: () => { },
      hideSetSchools: () => { },
      showCoordinatorSetSchools: () => { },
      hideCoordinatorSetSchools: () => { },
      showCoordinatorEditSchool: () => { },
      hideCoordinatorEditSchool: () => { },
      showCoordinatorEditCity: () => { },
      hideCoordinatorEditCity: () => { },
	    showCoordinatorEditDistrict: () => { },
      hideCoordinatorEditDistrict: () => { },
      saveNewTasksForTeacherVariant: () => { }
  }
);

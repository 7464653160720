//Staff management service, i.e. edit/add/delete reset pw by admin etc.

//import config from 'config';
import { get, post, put, remove, postFile } from "../_methods/download";

export const reportCategoryService = {
  getAll,
  getById,
  create,
  update,
  removeCategory,
  getAllActive,
  resetStated,
  resetStatement,
  registerDb
};

async function getAll(page, pageSize) {
  let pageFilter = page ? `&page=${page}` : '';
  let pageSizeFilter = pageSize ? `&pageSize=${pageSize}` : '';
  return await get(`/api/reportCategories?deleted=false${pageFilter}${pageSizeFilter}`, true);
}
async function getAllActive() {
  return await get(`/api/reportCategories/activeList`, true);
}

async function getById(id) {
  return await get(`/api/reportCategories/${id}`, true);
}

async function create(model) {
  return await post(`/api/reportCategories`, true, model);
}

async function update(id, model) {
  return await put(`/api/reportCategories/${id}`, true, model);
}

async function removeCategory(id) {
  return await remove(`/api/reportCategories/${id}`, true);
}

async function resetStated(userId, reportCategoryId) {
  return await post(`/api/admin/coordinators/${userId}/resetStated/${reportCategoryId}`, true);
}

async function resetStatement(userId, reportCategoryId) {
  return await post(`/api/admin/coordinators/${userId}/resetStatement/${reportCategoryId}`, true);
}

async function registerDb(reportCategoryId, model) {
  return await post(`/api/reportCategories/${reportCategoryId}/setConnection`, true, model);
}

export default reportCategoryService;

//import auth from './../_services/authentication.service';
import userService from "./../_services/user.service";
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/ru';

export function GetTimeByTimeZone(utcTime) {
    moment().lang("ru");
    let result = null;
    
    const localTimeZoneIdIana = userService.currentUser.source._value != null ? userService.currentUser.source._value.localTimeZoneIdIana : null;

    if (utcTime != null && localTimeZoneIdIana != null)
    {
        result = moment.utc(utcTime).tz(localTimeZoneIdIana);
    }

    return result;
}

export function GetTimeByTimeZoneOrDefault(utcTime) {
    moment().lang("ru");
    let result = null;

    const localTimeZoneIdIana = userService.currentUser.source._value != null ? userService.currentUser.source._value.localTimeZoneIdIana : null;

    let mUtc = moment.utc(utcTime);

    if (utcTime != null && localTimeZoneIdIana != null) {
        result = mUtc.tz(localTimeZoneIdIana);
    } else {
        result = mUtc.local();
    }

    return result;
}

export function GetCurrentTimeByTimeZone() {
    return GetTimeByTimeZone(Date.now());
}

//Staff management service, i.e. edit/add/delete reset pw by admin etc.

//import config from 'config';
import { get, post, put, remove, postFile } from "../_methods/download";

export const coordinatorService = {
  getCoordinator,
  getCategories,
  getCategory,
  getContests,
  getContest,
  getSchools,
  getCoordinatorSchools,
  getPayments,
  getRequestsByContestId,
  getReportCategories,
  getNotPaidRequests,
  getMyPayments,
  getMyRequests,
  setSchoolMasks,
  removeSchool,
  getMyActiveRequests,
  addRequests,
  getPaymentDetails,
  getSchoolStats,
  getContestStats,
  getMyActiveStatements,
  addStatements,
  getClassesForStatements,
  showCoordinatorSchools,
  checkCountSchoolsForRequests,
  checkCountSchoolsForStatements
  , updateCoordinatorSchool
  , createCoordinatorSchool
  , removeCoordinatorSchool
  , submitCategoryDataStated
  , submitCategoryDataStatement
  , uploadStatements
  , uploadStats
  , uploadResults
  , createResultsFileStatus
};

async function getCoordinator(coordinatorId) {
  return await get(`/api/admin/coordinators/${coordinatorId}`, true);
}

async function getCoordinatorSchools(coordinatorId, page, pageSize, filter) {
  const schoolCode = filter && filter.schoolCode ? `&sc=${filter.schoolCode}` : "";
  const regionCode = filter && filter.regionCode ? `&rc=${filter.regionCode}` : "";
  const cityCode = filter && filter.cityCode ? `&cc=${filter.cityCode}` : "";
  const school = filter && filter.school ? `&school=${filter.school}` : "";
  return await get(`/api/admin/coordinators/${coordinatorId}/schools?page=${page}&pageSize=${pageSize}${regionCode}${cityCode}${schoolCode}${school}`, true);
}

async function getCategories(coordinatorId, page, pageSize) {
  return await get(`/api/admin/coordinators/${coordinatorId}/categories?page=${page}&pageSize=${pageSize}`, true);
}

async function getCategory(coordinatorId, id) {
  return await get(`/api/admin/coordinators/${coordinatorId}/categories/${id}`, true);
}

async function getContests(coordinatorId, categoryId, page, pageSize) {
  return await get(`/api/admin/coordinators/${coordinatorId}/contests?page=${page}&pageSize=${pageSize}&category=${categoryId}`, true);
}

async function getContest(coordinatorId, id) {
  return await get(`/api/admin/coordinators/${coordinatorId}/contests/${id}`, true);
}

async function getPayments(coordinatorId, page, pageSize, request = null) {
  request = request ? `&request=${request}` : "";
  return await get(`/api/admin/coordinators/${coordinatorId}/payments?page=${page}&pageSize=${pageSize}${request}`, true);
}

async function getMyPayments(coordinatorId, page, pageSize, filter) {
  let school = filter && filter.schoolId ? `&school=${filter.schoolId}` : "";
  const codeSchool = filter && filter.codeSchool ? `&codeSchool=${filter.codeSchool}` : "";
  const sumLt = filter && filter.sumLt ? `&sumLte=${filter.sumLt}` : "";
  const category = filter && filter.category ? `&category=${filter.category}` : "";
  const createdAtFrom = filter && filter.createdAtFrom ? `&createdAtGte=${filter.createdAtFrom}` : "";
  const createdAtTo = filter && filter.createdAtTo ? `&createdAtLte=${filter.createdAtTo}` : "";
  const status = '';//filter && filter.status ? `&status=${filter.status}` : "";
  const sumGt = filter && filter.sumGt ? `&sumGte=${filter.sumGt}` : "";
  return await get(`/api/admin/coordinators/${coordinatorId}/payments?page=${page}&pageSize=${pageSize}${school}${codeSchool}${category}${sumGt}${createdAtFrom}${createdAtTo}${status}${sumLt}`, true);
}

async function getPaymentDetails(coordinatorId, contestId, page, pageSize) {
  return await get(`/api/admin/coordinators/${coordinatorId}/schoolsStats?contest=${contestId}&page=${page}&pageSize=${pageSize}`, true);
}

async function getSchoolStats(coordinatorId, reportCategoryId, page, pageSize) {
  return await get(`/api/admin/coordinators/${coordinatorId}/schoolsStats?page=${page}&pageSize=${pageSize}&reportCategory=${reportCategoryId}`, true);
}

async function getContestStats(coordinatorId, schoolId, categoryId, page, pageSize) {
  return await get(`/api/admin/coordinators/${coordinatorId}/contests?page=${page}&pageSize=${pageSize}&school=${schoolId}&category=${categoryId}`, true);
}

async function getRequestsByContestId(contestId, page, pageSize) {
  return await get(`/api/coordinators/${contestId}/requests?page=${page}&pageSize=${pageSize}`, true);
}

async function getReportCategories(coordinatorId, page, pageSize) {
  return await get(`/api/coordinators/${coordinatorId}/reportCategories?page=${page}&pageSize=${pageSize}`, true);
}

async function getNotPaidRequests(coordinatorId, paymentId, page, pageSize) {
  return await get(` /api/admin/coordinators/${coordinatorId}/payments/${paymentId}/requests`, true);
}

async function getSchools(coordinatorId) {
  return await get(`/api/admin/coordinators/${coordinatorId}/schools`, true);
}

async function setSchoolMasks(coordinatorId, model) {
  return await post(`/api/admin/coordinators/${coordinatorId}/setSchoolMasks`, true, model);
}

async function removeSchool(coordinatorId, schoolId) {
  return await remove(`/api/admin/coordinators/${coordinatorId}/schools/${schoolId}`, true);
}

async function getMyRequests(coordinatorId, page, pageSize, filter) {
  let contest = filter && filter.contest ? `&contest=${filter.contest}` : "";
  let school = filter && filter.school ? `&school=${filter.school}` : "";
  let region = filter && filter.region ? `&region=${filter.region}` : "";
  return await get(`/api/coordinators/${coordinatorId}/requests?page=${page}&pageSize=${pageSize}${contest}${school}${region}`, true);
}

async function getMyActiveRequests(coordinatorId, categoryId, page, pageSize, filter) {
  let contest = filter?.contest ? `&contest=${filter.contest}` : "";
  let school = filter?.school ? `&school=${filter.school}` : "";
  let region = filter?.region ? `&district=${filter.region}` : "";
  return await get(`/api/coordinators/${coordinatorId}/requests/${categoryId}/active?page=${page}&pageSize=${pageSize}${contest}${school}${region}`, true);
}

//async function getMyActiveStatements(coordinatorId, categoryId, page, pageSize, filter) {
//  return await get(`/api/coordinators/${coordinatorId}/availableClasses/${categoryId}?page=${page}&pageSize=${pageSize}`, true);
//}

async function getMyActiveStatements(coordinatorId, categoryId, page, pageSize, filter) {
  return await get(`/api/coordinators/${coordinatorId}/statement/${categoryId}/active?page=${page}&pageSize=${pageSize}`, true);
}

async function addRequests(coordinatorId, model) {
  return await post(`/api/coordinators/${coordinatorId}/requests/update`, true, model);
}

async function addStatements(coordinatorId, model) {
  return await post(`/api/coordinators/${coordinatorId}/statements/update`, true, model);
}

//async function getClassesForStatements(coordinatorId, reportCategoryId) {
//  return await get(`/api/coordinators/${coordinatorId}/availableClasses/${reportCategoryId}`, true);
//}

async function getClassesForStatements(coordinatorId, reportCategoryId, page, pageSize, filter) {
  let school = filter && filter.school ? `&school=${filter.school}` : "";
  let region = filter && filter.region ? `&district=${filter.region}` : "";
  return await get(`/api/coordinators/${coordinatorId}/statement/${reportCategoryId}/active?page=${page}&pageSize=${pageSize}${school}${region}`, true);
}

async function createCoordinatorSchool(model) {
  return await post(`/api/schools/create/coordinator`, true, model);
}

async function showCoordinatorSchools(coordinatorId, model) {
  return await post(`/api/coordinators/${coordinatorId}/showSchool`, true, model);
}

async function updateCoordinatorSchool(id, model) {
  return await post(`/api/schools/update/${id}/coordinator`, true, model);
}

async function removeCoordinatorSchool(coordinatorId, schoolId) {
  //return await remove(`api/coordinators/${coordinatorId}/removeSchool/${schoolId}`, true);
  return await post(`api/coordinators/${coordinatorId}/hideSchool/${schoolId}`, true);
}

async function submitCategoryDataStated(coordinatorId, categoryId) {
  return await post(`/api/coordinators/${coordinatorId}/submitCategoryDataStated/${categoryId}`, true, null);
}

async function submitCategoryDataStatement(coordinatorId, categoryId) {
  return await post(`/api/coordinators/${coordinatorId}/submitCategoryDataStatement/${categoryId}`, true, null);
}

async function checkCountSchoolsForRequests(coordinatorId, categoryId) {
  return await get(`/api/coordinators/${coordinatorId}/requests/${categoryId}/active/count`, true);
}

async function checkCountSchoolsForStatements(coordinatorId, categoryId) {
  return await get(`/api/coordinators/${coordinatorId}/statement/${categoryId}/active/count`, true);
}

async function uploadStats(coordinatorId, categoryId) {
  let authData = localStorage.getItem("currentAuth");
  if (authData) {
    authData = JSON.parse(authData);
    let link = window.location.protocol + "//" + window.location.host + `/api/coordinators/${coordinatorId}/statsExcel/${categoryId}?token=${authData.access_token}`;
    return link;
  }
  return null;
}

async function uploadStatements(coordinatorId) {
  let authData = localStorage.getItem("currentAuth");
  if (authData) {
    authData = JSON.parse(authData);
    let link = window.location.protocol + "//" + window.location.host + `/api/coordinators/${coordinatorId}/statementsExcel?token=${authData.access_token}`;
    return link;
  }
  return null;
}

async function createResultsFileStatus(coordinatorId, categoryId) {
  return await post(`/api/coordinators/${coordinatorId}/zip/${categoryId}`, true, null);
}

async function uploadResults(url) {
  let authData = localStorage.getItem("currentAuth");
  if (authData) {
    authData = JSON.parse(authData);
    let link = window.location.protocol + "//" + window.location.host + '/' + url + `?token=${authData.access_token}`;
    return link;
    //api/coordinators/14332/zip/eTkyUklI?token=
  }
  return null;
}

export default coordinatorService;

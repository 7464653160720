import { get, post, remove, put } from "../_methods/download";

export const variantService = {
  getVariants,
  getVariant,
  updateVariant,
  createVariant,
  getPublicVariant,
  getTeacherVariants,
  getTeacherVariant,
  createTeacherVariant,
  updateTeacherVariant,
  removeTeacherVariant,
  getTeacherVariantsForAdmin,
  checkCreateVariant,
  updatePdfLinks,
  getUpdatePdfStatus,
  lockVariant,
  unlockVariant
};

async function getVariants(type, page, pageSize, filter) {
  return await get(`/api/variants?type=${type}&page=${page}&pageSize=${pageSize}`, true);
}

async function getVariant(id) {
  return await get(`/api/variants/${id}`, true);
}

async function getPublicVariant(id) {
  return await get(`/api/variants/${id}/preview`, true);
}

async function updateVariant(id, model) {
  return await put(`/api/variants/${id}`, true, model);
}

async function createVariant(model) {
  return await post(`/api/variants`, true, model);
}

async function getTeacherVariants(page, pageSize, filter) {
  return await get(`/api/teacher/variants?page=${page}&pageSize=${pageSize}&status=1,2`, true);
}

async function getTeacherVariant(variantId) {
  return await get(`/api/teacher/variants/${variantId}`, true);
}

async function updateTeacherVariant(variantId, model) {
  return await put(`/api/teacher/variants/${variantId}`, true, model);
}

async function createTeacherVariant(model) {
  return await post(`/api/teacher/variants`, true, model);
}

async function removeTeacherVariant(variantId) {
  return await remove(`/api/teacher/variants/${variantId}`, true);
}

async function checkCreateVariant() {
  return await get(`/api/teacher/variants/canCreate`, true);
}

async function getTeacherVariantsForAdmin(page, pageSize, filter) {
  let teacher = filter && filter.teacherId ? `&teacherId=${filter.teacherId}` : ``;
  return await get(`/api/variants?teachers=true&page=${page}&pageSize=${pageSize}${teacher}`, true);
}

async function updatePdfLinks(variantId) {
  return await post(`/api/variants/${variantId}/updatePdf`, true);
}

async function getUpdatePdfStatus(id) {
  return await get(`/api/variants/${id}/status`, true);
}

async function lockVariant(id) {
  return await post(`/api/variants/${id}/lock`, true);
}

async function unlockVariant(id) {
  return await post(`/api/variants/${id}/unlock`, true);
}

export default variantService;

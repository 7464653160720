import { BehaviorSubject } from 'rxjs';
import userService from "./../_services/user.service";
import { get, post } from "../_methods/download";
import { hasProperty } from "lodash";

var user = localStorage.getItem('currentAuth') ? localStorage.getItem('currentAuth') : null;
const currentAuthSubject = new BehaviorSubject(JSON.parse(user));

export const auth = {
    login,
    forgotPassword,
    restorePassword,
    restorePasswordConfirm,
    resetStaffPassword,
    needSetPassword,
    logout,
    isAuth,
    isAdmin,
    isConfirmed,
    getUser,
    getAccessToken,
    updateAccessToken,
    resetPassword,
    resetPasswordPost,
    changeAuthValue,
    resetPasswordWithToken,
    getByEmailToken,
    regByEmailSendSms,
    regByEmailSendSmsConfirm,
    currentUser: currentAuthSubject.asObservable(),
    get currentUserValue() { return currentAuthSubject.value }
};

function isAuth() {
    return auth.currentUser.source._value != null;
}

function needSetPassword() {
    return auth.currentUser.source._value != null && auth.currentUser.source._value.needSetPassword != null ? true : false;
}

function getAccessToken() {
    return auth.currentUserValue != null ? auth.currentUserValue.access_token : null;
}

function isConfirmed() {
  return auth.currentUserValue ? auth.currentUserValue.isConfirmed : false;
}

function isAdmin() {
  if (auth.currentUserValue && hasProperty(auth.currentUserValue.isAdmin))
    return auth.currentUserValue ? auth.currentUserValue.isAdmin : false;
}

async function updateAccessToken(token, expires) {
  let user = currentAuthSubject.getValue();
  if (user) {
    user.access_token = token;
    user.expires_in = expires;
  } else {
    user = {
      access_token: token,
      expires_in: expires
    }
  }

  localStorage.setItem('currentAuth', JSON.stringify(user));
  await currentAuthSubject.next(user);
}
function getUser() {
    return auth.currentUserValue ? auth.currentUserValue : null;
}

async function resetPassword(id) {
    await get(`/api/password/reset`, true);
}

async function resetPasswordPost(model) {
  var result = await post(`/api/auth/password/reset`, false, model);
  if (result) {
    if (result.error) {
      if (result.error.errorType === 2)
        return Promise.reject(result.error.errorType);
      return Promise.reject(result.error.message);
    }

    localStorage.setItem('currentAuth',
      JSON.stringify({
        expires_in: result.expires_in,
        expires_in_timestamp: result.expires_in_timestamp,
        access_token: result.access_token
      }));

    //saving user account to user service variable
    currentAuthSubject.next(result);
    userService.refreshUser();
    return auth.currentUser;
  }

  return null;
}

async function resetPasswordWithToken(model) {
  var result = await post(`/api/auth/password/change`, false, model);
  if (result) {
    if (result.error) {
      if (result.error.errorType === 2)
        return Promise.reject(result.error.errorType);
      return Promise.reject(result.error.message);
    }
    result = result.data ? result.data : result;
    localStorage.setItem('currentAuth',
      JSON.stringify({
        expires_in: result.expires_in,
        expires_in_timestamp: result.expires_in_timestamp,
        access_token: result.access_token
      }));

    //saving user account to user service variable
    await currentAuthSubject.next(result);
    await userService.refreshUser();
    return auth.currentUser;
  }
  return null;
}

async function resetStaffPassword(email, companyId) {
    return await post(`/api/account/resetPassword`, true, JSON.stringify({ email, companyId }));
}

async function login(model) {
  const response = await post(`/api/auth/login`, false, model);
  if (response && response.data) {
    localStorage.setItem('currentAuth', JSON.stringify({
      expires_in: response.data.expires_in
      , expires_in_timestamp: response.data.expires_in_timestamp
      , refreshToken: response.data.refreshToken
      , access_token: response.data.access_token
      , refreshTokenExpires: response.data.refreshTokenExpires
    }));

    currentAuthSubject.next(response.data);
  }
  return response;
}

async function forgotPassword(model) {
    var result = await post(`/api/auth/password/email`, false, model);
    return result;
}
async function restorePassword(model) {
    return await post(`api/auth/password/restore`, false, model);
}

async function regByEmailSendSms(model) {
  return await post(`api/auth/regByEmailSendSms`, false, model);
}

async function regByEmailSendSmsConfirm(model) {
  let response = await post(`api/auth/regByEmailSendSmsConfirm`, false, model);
  if (response && response.success) {
    const authObj = {
      expires_in: response.data.expires_in,
      expires_in_timestamp: response.data.expires_in_timestamp,
      refreshToken: response.data.refreshToken,
      access_token: response.data.access_token,
      refreshTokenExpires: response.data.refreshTokenExpires
    };
    localStorage.setItem('currentAuth', JSON.stringify(authObj));
    await auth.changeAuthValue(authObj);
    return response;
  } else
    return response;
}

async function getByEmailToken(token) {
  return await post(`api/auth/getByEmailToken`, false, JSON.stringify({ token }));
}

async function restorePasswordConfirm(model) {
  return await post(`api/auth/password/restore/confirm`, false, model);
}

async function changeAuthValue(model) {
  await currentAuthSubject.next(model);
  await userService.refreshUser();
}

async function logout() {
      localStorage.removeItem('currentAuth');
      localStorage.removeItem('currentUserData');
      localStorage.removeItem('token');
      currentAuthSubject.next(null);
      window.location.href = '/#/student/request';
}

export default auth;

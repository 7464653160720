//Staff management service, i.e. edit/add/delete reset pw by admin etc.

//import config from 'config';
import { get, post, put, remove } from "../_methods/download";

export const contestantService = {
  testStart,
  testFinish,
  answerTask,
  getTask,
  getTasks,
  removeContestants,
  getTopSchoolContestants,
  getTopClassContestants,
  resetStatus,
  retakeTest,
  forcedFinish,
  printCertificate,
  printDiploma

};

async function testStart(id) {
  return await get(`api/contestants/${id}/start`, true);
}

async function testFinish(id) {
  return await get(`api/contestants/${id}/finish`, true);
}

async function forcedFinish(id) {
  return await get(`api/contestants/${id}/forcedFinish`, true);
}

async function answerTask(id, taskId, variantId, type) {
  //let model = type !== 3 ? { type, answer: variantId } : { type, answerText: variantId } ;
  let model = { type, answer: variantId };
  return await post(`api/contestants/${id}/answer/${taskId}`, true, JSON.stringify(model));
}

async function getTask(id, taskId) {
  return await get(`api/contestants/${id}/tasks/${taskId}`, true);
}

async function getTasks(id) {
  return await get(`api/contestants/${id}/tasks`, true);
}

async function removeContestants(ids) {
  return await remove(`api/contestants?id=${ids}`, true);
}

async function getTopClassContestants(requestId) {
  //return topClassContestants;
  return await get(`/api/requests/group/${requestId}/contestant/results/topClass`, true);
}

async function getTopSchoolContestants(requestId) {
  //return topClassContestants;
  return await get(`/api/requests/group/${requestId}/contestant/results/topSchool`, true);
}

async function resetStatus(contestantId) {
  return await get(`/api/contestants/${contestantId}/reset`, true);
}

async function retakeTest(contestantId) {
  return await get(`/api/contestants/${contestantId}/resetForRetest`, true);
}

function printCertificate(contestantId) {
  let authData = localStorage.getItem("currentAuth");
  if (authData) {
    authData = JSON.parse(authData);
    let link = window.location.protocol + "//" + window.location.host + `/api/contestants/${contestantId}/certificate?token=${authData.access_token}`;
    return link;
  }
  return null;
}

function printDiploma(contestantId) {
  let authData = localStorage.getItem("currentAuth");
  if (authData) {
    authData = JSON.parse(authData);
    let link = window.location.protocol + "//" + window.location.host + `/api/contestants/${contestantId}/diploma?token=${authData.access_token}`;
    return link;
  }
  return null;
}


export default contestantService;
